import { AppLangEnum } from '@shared/enums/app-lang.enum';
import { environment } from '@root/environments/environment';

export class AppStorage {
  static storageKeys = {
    language: 'language',
    reportScanModel: 'reportScanModel',
  };

  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static setItem(key: string, val: string) {
    localStorage.setItem(key, val);
  }

  static deleteItem(key: string) {
    localStorage.removeItem(key);
  }

  static getLanguage(): AppLangEnum {
    return (localStorage.getItem(this.storageKeys.language) ??
      environment.default_lang) as AppLangEnum;
  }

  static setLanguage(lang: AppLangEnum) {
    return localStorage.setItem(this.storageKeys.language, lang);
  }
}
